import React from "react";
import Layout from "../components/Layout";
import {Link} from "gatsby";

const Service_one = ({location}) => {
    const {state = {}} = location
    return (
        <Layout>
            <div id="main">
                <div className="services_section_banner">
                    <div className="services_banner" style={{backgroundImage: `url(../images/assists.jpg)`}}>
                        <h1>Asistencias</h1>
                    </div>
                </div>
                <div className="inner">
                    <div className="services_section_1">
                        <h1 className="services_section_1__title">Descripción</h1>
                        <p className="services_section_1__paragraph">
                            Especialistas en el diseño de nuevos productos y modelos de negocio. Ofrecemos una solución
                            perfecta a las necesidades de los clientes, gracias a un gran entendimiento de la industria
                            y al diseño del producto.
                        </p>
                    </div>
                    <div className="card">
                        <div className="card__content">
                            <h1 style={{color: '#000000', fontSize: 20}}>Algunos de nuestros productos:</h1>
                            <section className="tiles">
                                <article>
                                    <ul className="alt" style={{color: '#000000'}}>
                                        <li><span style={{fontWeight: 'bold'}}>-</span> Asistencia Vial</li>
                                        <li><span style={{fontWeight: 'bold'}}>-</span> Auxilio Vial</li>
                                        <li><span style={{fontWeight: 'bold'}}>-</span> Gestoría para Autos</li>
                                        <li><span style={{fontWeight: 'bold'}}>-</span> Asesoría Legal</li>
                                        <li><span style={{fontWeight: 'bold'}}>-</span> Asistencia en Viajes</li>
                                        <li><span style={{fontWeight: 'bold'}}>-</span> Asistencia Hogar</li>
                                        <li><span style={{fontWeight: 'bold'}}>-</span> Asistencia Laboral</li>
                                        <li><span style={{fontWeight: 'bold'}}>-</span> Concierge</li>
                                        <li><span style={{fontWeight: 'bold'}}>-</span> Mediphone</li>
                                        <li><span style={{fontWeight: 'bold'}}>-</span> Asistencia Funeraria</li>
                                        <li><span style={{fontWeight: 'bold'}}>-</span> Plataforma CRM</li>
                                        <li><span style={{fontWeight: 'bold'}}>-</span> Respaldo PC</li>
                                        <li><span style={{fontWeight: 'bold'}}>-</span> Paquete Pyme Productividad</li>
                                        <li><span style={{fontWeight: 'bold'}}>-</span> Asistencia de Ambulancia y/o
                                            médico a domicilio
                                        </li>
                                    </ul>
                                </article>
                                <article>
                                    <ul className="alt" style={{color: '#000000'}}>
                                        <li><span style={{fontWeight: 'bold'}}>-</span> Dental</li>
                                        <li><span style={{fontWeight: 'bold'}}>-</span> Visión</li>
                                        <li><span style={{fontWeight: 'bold'}}>-</span> Consultas médicas y medicamentos
                                            sin costo
                                        </li>
                                        <li><span style={{fontWeight: 'bold'}}>-</span> Llamadas nacionales e
                                            internacionales
                                        </li>
                                        <li><span style={{fontWeight: 'bold'}}>-</span> Marido Sustituto</li>
                                        <li><span style={{fontWeight: 'bold'}}>-</span> Programa de Descuentos</li>
                                        <li><span style={{fontWeight: 'bold'}}>-</span> Lifestyle</li>
                                        <li><span style={{fontWeight: 'bold'}}>-</span> Médico</li>
                                        <li><span style={{fontWeight: 'bold'}}>-</span> Mascotas</li>
                                        <li><span style={{fontWeight: 'bold'}}>-</span> Nutricional</li>
                                        <li><span style={{fontWeight: 'bold'}}>-</span> Fitness</li>
                                        <li><span style={{fontWeight: 'bold'}}>-</span> Auto</li>
                                        <li><span style={{fontWeight: 'bold'}}>-</span> Sustituto</li>
                                        <li><span style={{fontWeight: 'bold'}}>-</span> MaiAssist</li>
                                    </ul>
                                </article>
                                <article>
                                    <ul className="alt" style={{color: '#000000'}}>
                                        <li><span style={{fontWeight: 'bold'}}>-</span> Cost Control (Siniestros)</li>
                                        <li><span style={{fontWeight: 'bold'}}>-</span> Garantía TV</li>
                                        <li><span style={{fontWeight: 'bold'}}>-</span> Garantía de Electrodomésticos
                                        </li>
                                        <li><span style={{fontWeight: 'bold'}}>-</span> Garantía Extendida de Vehículos
                                            (N y U)
                                        </li>
                                        <li><span style={{fontWeight: 'bold'}}>-</span> Iwarranty</li>
                                        <li><span style={{fontWeight: 'bold'}}>-</span> Protección de Identidad</li>
                                        <li><span style={{fontWeight: 'bold'}}>-</span> Uso Fraudulento</li>
                                        <li><span style={{fontWeight: 'bold'}}>-</span> Robo de Celular / Laptop /
                                            Tablets
                                        </li>
                                        <li><span style={{fontWeight: 'bold'}}>-</span> Cartera Protegida</li>
                                        <li><span style={{fontWeight: 'bold'}}>-</span> GAP</li>
                                        <li><span style={{fontWeight: 'bold'}}>-</span> Protección de Pagos</li>
                                        <li><span style={{fontWeight: 'bold'}}>-</span> Protección de Compras</li>
                                        <li><span style={{fontWeight: 'bold'}}>-</span> Robo en ATM</li>
                                        <li><span style={{fontWeight: 'bold'}}>-</span> Garantía de Mejor Precio</li>
                                        <li><span style={{fontWeight: 'bold'}}>-</span> Protección a Llantas</li>
                                    </ul>
                                </article>
                            </section>
                            <div>
                                <h1 style={{color: '#000000', fontSize: 20}}>Asistencias:</h1>
                                <section className="card-assists">
                                    <article style={{border: '3px solid #072146'}}>
                                        <div style={{padding: 10}}>
                                            <h1 style={{fontSize: 20, color: '#000000'}}>B2B</h1>
                                            <p style={{color: '#000000'}}>Oferta de Valor Inclusión Cost Control.</p>
                                        </div>
                                    </article>
                                    <article style={{border: '3px solid #072146'}}>
                                        <div style={{padding: 10}}>
                                            <h1 style={{fontSize: 20, color: '#000000'}}>B2B2C</h1>
                                            <p style={{color: '#000000'}}>Telemarketing Venta 1 a 1 Canvaseo</p>
                                        </div>
                                    </article>
                                </section>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Layout>
    )
}

export default Service_one
